/* eslint-disable import/named */
import * as React from 'react'

import { graphql } from 'gatsby'

import {
  ContentfulRichTextGatsbyReference,
  RenderRichTextData,
} from 'gatsby-source-contentful/rich-text'

import { Footer, Header, Info, Layout, SEO } from '@components'

type DataProps = {
  readonly data: {
    allContentfulDisclaimers: {
      edges: Array<{
        node: { body: RenderRichTextData<ContentfulRichTextGatsbyReference> }
      }>
    }
  }
}

const Head = (props: any) => <SEO {...props} />

/**
 * @param data.allContentfulDisclaimers    Rich text for disclaimers.
 */
const Disclaimers: React.FC<DataProps> = ({ data }) => {
  return (
    <Layout>
      <Head
        title="Disclaimers"
        description="This is an unofficial fan site.
          We are not affiliated with Borderlands or Gearbox."
      />
      <Header />
      <Info body={data.allContentfulDisclaimers.edges[0].node.body} />
      <Footer />
    </Layout>
  )
}

export default Disclaimers

export const query = graphql`
  query {
    allContentfulDisclaimers(limit: 1) {
      edges {
        node {
          body {
            raw
          }
        }
      }
    }
  }
`
